import React, { useContext, useState } from "react";
import { Box, Button, Text } from "grommet";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import { resendSignUpCode } from "aws-amplify/auth";
import urlpaths from "./urlpaths.json";
import { confirmSignUp } from "../../components/Auth/auth";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import AuthLayout from "components/AuthLayout";

export default function ConfirmSignUp() {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
  } = useForm({
    defaultValues: {
      email: state?.user.email,
    },
  });

  const verifyAccount = async () => {
    setError("");
    let { email, otp } = getValues();

    try {
      let res = await confirmSignUp(email, otp);
      setSuccess(true);
      dispatch({
        type: "SET_USER",
        payload: {
          email: email,
        },
      });
    } catch (err) {
      console.log("error confirming user", err);
      dispatch({
        type: "SET_MESSAGE",
        payload: {
          error: true,
          message: err.message,
        },
      });
      return;
    }
  };

  if (success) {
    navigate(urlpaths.login);
    dispatch({
      type: "SET_MESSAGE",
      payload: {
        error: false,
        message: "Account verified. Please login",
      },
    });
  }

  return (
    <AuthLayout>
      <Box className="container">
        <h1 className="text-center">Verify</h1>
        <p className="text-center">
          Your one-time password has been sent to your inbox
        </p>
        <form onSubmit={handleSubmit(verifyAccount)} id="loginForm">
          <Box align="center" width="100">
            <Box width="large">
              <Box width="100%" align="center" gap="xsmall">
                <Box width="100%">
                  <Box className="field_row">
                    <label htmlFor="email-sign-up-simple">
                      Email
                    </label>
                    <div className="input_wrapper">
                      <input
                        id="email-sign-up-simple"
                        aria-invalid={errors.email ? "true" : "false"}
                        className={errors.email ? "error" : ""}
                        {...register("email", {
                          required: "This field is required.",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Must be valid email. example@yourdomain.com",
                          },
                        })}
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        form="novalidatedform"
                      />
                      <svg
                        className="icon"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.344 5.344V4L8 7.344 2.656 4v1.344L8 8.656l5.344-3.312zm0-2.688c.354 0 .656.135.906.406.271.271.406.583.406.938v8c0 .354-.135.667-.406.938-.25.271-.552.406-.906.406H2.656c-.354 0-.667-.135-.937-.406A1.34 1.34 0 0 1 1.344 12V4a1.34 1.34 0 0 1 .375-.937c.271-.271.583-.406.937-.406h10.688z"></path>
                      </svg>
                    </div>
                    {errors.email && (
                      <div className="msg-error">
                        {errors.email.message}
                      </div>
                    )}
                  </Box>
                  <Box className="field_row">
                    <label htmlFor="email-sign-up-simple">
                      Enter the OTP
                    </label>
                    <div className="input_wrapper">
                      <input
                        aria-invalid={errors.otp ? "true" : "false"}
                        className={errors.otp ? "error" : ""}
                        {...register("otp", {
                          required: "This field is required.",
                        })}
                        name="otp"
                        type="text"
                        placeholder="Enter your otp"
                        form="novalidatedform"
                      />
                    </div>
                    {errors.otp && (
                      <div className="msg-error">
                        {errors.otp.message}
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Button
                className="primary_button"
                label="Verify"
                size="large"
                primary
                type="submit"
              />
            </Box>
          </Box>
        </form>
      </Box>
    </AuthLayout>
  );
}
